<template>
    <div class="close-header-title no-con">
        <section class="privacy-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4" v-if="this.loginUser.id == 1">
                        <div class="widget widget-stat bg-inverse text-white">
                            <div class="widget-stat-btn"><a @click.prevent="get_state()"><i
                                        class="fa fa-repeat"></i></a>
                            </div>
                            <div class="widget-stat-icon"><i class="fa fa-users"></i></div>
                            <div class="widget-stat-info">
                                <div class="widget-stat-title">User</div>
                                <div class="widget-stat-number">{{ this.stats.users }}</div>
                                <div class="widget-stat-text"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="widget widget-stat bg-success1 text-white">
                            <div class="widget-stat-btn"><a @click.prevent="get_state()"><i
                                        class="fa fa-repeat"></i></a>
                            </div>
                            <div class="widget-stat-icon"><i class="fa fa-list"></i></div>
                            <div class="widget-stat-info">
                                <div class="widget-stat-title">Properties</div>
                                <div class="widget-stat-number">{{ this.stats.properties }}</div>
                                <div class="widget-stat-text"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="widget widget-stat bg-primary1 text-white">
                            <div class="widget-stat-btn"><a @click.prevent="get_state()"><i
                                        class="fa fa-repeat"></i></a>
                            </div>
                            <div class="widget-stat-icon"><i class="fa fa-ticket"></i></div>
                            <div class="widget-stat-info">
                                <div class="widget-stat-title">Bookings</div>
                                <div class="widget-stat-number">{{ this.stats.bookings }}</div>
                                <div class="widget-stat-text"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" v-if="this.loginUser.id != 1">
                        <div class="widget widget-stat bg-inverse1 text-white">
                            <div class="widget-stat-btn"><a @click.prevent="get_state()"><i
                                        class="fa fa-repeat"></i></a>
                            </div>
                            <div class="widget-stat-icon"><i class="fa fa-ban"></i></div>
                            <div class="widget-stat-info">
                                <div class="widget-stat-title">Pending Approve</div>
                                <div class="widget-stat-number">{{ this.stats.pending }}</div>
                                <div class="widget-stat-text"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4" v-if="this.loginUser.id == 1">
                        <div class="widget widget-stat bg-inverse1 text-white">
                            <div class="widget-stat-btn"><a @click.prevent="get_state()"><i
                                        class="fa fa-repeat"></i></a>
                            </div>
                            <div class="widget-stat-icon"><i class="fa fa-ban"></i></div>
                            <div class="widget-stat-info">
                                <div class="widget-stat-title">Pending Approve</div>
                                <div class="widget-stat-number">{{ this.stats.pending }}</div>
                                <div class="widget-stat-text"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'Dashboard',
    components: {
    },
    data() {
        return {
            loginUser: '',
            stats: '',
        }
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem("user"));
        this.loginUser = user;
        this.get_state();
    },
    methods: {
        get_state() {
            API.getdashboardstats(this.loginUser.id, fevData => {
                this.stats = fevData.data;
            });
        }
    },
    computed: {
    },
    created() {
    },
}
</script>
<style>
.widget.widget-stat {
    text-align: center;
}

.widget {
    border: none;
    box-shadow: 0 2px 0 rgb(0 0 0 / 7%);
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    background: #fff;
    padding: 20px;
    display: block;
}

.text-white {
    color: #fff;
}

.bg-inverse {
    background: #69D4FF !important;
}

.bg-inverse1 {
    background: #70d3ff !important;
}

.widget-stat-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1000;
}

.widget-stat-btn a {
    color: #000;
    opacity: .3;
    display: block;
    text-decoration: none;
}

.widget-stat-btn a .fa {
    display: block;
    font-size: 13px;
}

.widget-stat-icon {
    width: 50px;
    float: left;
    font-size: 40px;
    color: #000;
}

.bg-success1 {
    background: #70D3FF !important;
}

.bg-primary1 {
    background: #70d3ff !important;
}

.widget-stat-info {
    margin-left: 70px;
    text-align: left;
}
</style>